export default {
  "home": {
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Bracelet"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est requis"])},
    "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant: "])},
    "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant de réservation introuvable"])},
    "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'a pas le minimum de nuits pour réserver"])},
    "t7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "t8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure"])},
    "t9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Personnes"])},
    "t10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
    "t11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarques: (Allergies, célébrations ou demandes spéciales)"])},
    "t12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les disponibilités"])},
    "t13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Réservation créée"])},
    "t14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Vous avez déjà une réservation pour cette date"])},
    "t15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Nombre maximum de réservations atteint"])},
    "t16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Restaurant sans disponibilité"])},
    "t17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* En dehors des heures de réservation"])},
    "t18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingles"])},
    "t19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code vestimentaire: Formel (Messieurs: chaussure fermée, short ou pantalon habillé, chemise à manches)"])},
    "t20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez noter que la tolérance de réservation est de 10 minutes."])},
    "t21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le pourboire n'est pas inclus."])},
    "t22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas assez de nuit de séjour ou réservation disponible déjà utilisée"])},
    "t23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour"])},
    "t24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", merci pour votre réservation !  "])},
    "t25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez réservé avec 'Petit-déjeuner seulement' comme plan de repas. Aucune réservation n'est requise pour Umami ou Chianti. Les réservations gratuites pour le dîner sont uniquement pour le régime 'All Inclusive' à partir de 3 à 5 nuits minimum."])},
    "t26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette réservation a déjà été validée"])},
    "t27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["faire une réservation"])}
  },
  "Por favor agregue una letra al inicio de acuerdo al color de su brazalete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'ajouter une lettre au début en fonction de la couleur de votre bracelet"])},
  "para amarillo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour le jaune"])},
  "para azul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour le bleu"])},
  "para blanco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour le blanc"])},
  "para gris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour le gris"])},
  "para negro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour le noir"])},
  "para rosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour le rose"])},
  "Selecciona el restaurante y horario disponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le restaurant et l'heure disponible"])},
  "¿Estas seguro que deseas cancelar tu reserva?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir annuler votre réservation ?"])},
  "Cancelar reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la réservation"])},
  "Reserva cancelada con éxito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservation annulée avec succès"])}
}