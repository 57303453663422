export default {
  "home": {
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Brazalete"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo es obligatorio"])},
    "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurante: "])},
    "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id de reserva no encontrada ES"])},
    "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cuenta con las noches mínimas para reservar"])},
    "t7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
    "t8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario"])},
    "t9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Personas"])},
    "t10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo"])},
    "t11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios"])},
    "t12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar Disponibilidad"])},
    "t13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Reserva Creada"])},
    "t14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Ya cuenta con una reserva para esa fecha"])},
    "t15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Numero máximo de reservas alcanzadas"])},
    "t16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Restaurante sin disponibilidad"])},
    "t17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Fuera de horario para reservar"])},
    "t18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingles"])},
    "t19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de vestir: Formal (Caballeros: zapato cerrado, pantalón o short de vestir, playera o camisa con mangas)"])},
    "t20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le recordamos que la toleracia es de 10 minutos."])},
    "t21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La propina no esta incluida."])},
    "t22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay suficientes noches de estadía o reserva disponible ya utilizada"])},
    "t23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Hola"])},
    "t24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", gracias por tu reserva!"])},
    "t25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si usted viene con “Solo Desayuno” como plan alimenticio. No requiere de hacer reservaciones para Umami o Chianti. Las reservaciones para las cenas en cortesía son únicamente para el plan alimenticio “Todo Incluido” con un mínimo de 3 a 5 noches en adelante."])},
    "t26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta reserva ya hizo checkout"])},
    "t27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear reserva"])}
  }
}