export default {
  "home": {
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Pulseira"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo é obrigatório"])},
    "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurante: "])},
    "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de reserva não encontrado"])},
    "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não tem noites mínimas para reservar"])},
    "t7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data:"])},
    "t8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora:"])},
    "t9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pessoas:"])},
    "t10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
    "t11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observações: (Alergias, comemorações ou pedidos especiais)"])},
    "t12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar Disponibilidade"])},
    "t13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Reserva criada"])},
    "t14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Você já tem uma reserva para essa data"])},
    "t15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Número máximo de reservas atingido"])},
    "t16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Restaurante sem disponibilidade"])},
    "t17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Fora de horário para reserva"])},
    "t18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingles"])},
    "t19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formal (Senhores: sapatos fechados, calça social ou shorts, camiseta ou camisa com mangas)"])},
    "t20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembramos que a tolerância é de 10 minutos."])},
    "t21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A ponta não está incluída."])},
    "t22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noite de estadia insuficiente ou reserva disponível já utilizada"])},
    "t23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olá "])},
    "t24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", obrigado pela sua reserva!"])},
    "t25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se você reservou com 'Somente café da manhã' como seu plano de refeição. Não são necessárias reservas para Umami ou Chianti. As reservas de cortesia para jantar são apenas para o plano de refeições 'All Inclusive' com um mínimo de 3 a 5 noites em diante."])},
    "t26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta reserva já foi verificada"])},
    "t27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fazer reserva"])}
  },
  "Por favor agregue una letra al inicio de acuerdo al color de su brazalete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor adicione uma letra no início de acordo com a cor da sua pulseira"])},
  "para amarillo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para amarelo"])},
  "para azul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para azul"])},
  "para blanco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para branco"])},
  "para gris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para cinza"])},
  "para negro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para preto"])},
  "para rosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para rosa"])},
  "Selecciona el restaurante y horario disponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o restaurante e o horário disponível"])},
  "¿Estas seguro que deseas cancelar tu reserva?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza de que deseja cancelar sua reserva?"])},
  "Cancelar reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar reserva"])},
  "Reserva cancelada con éxito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva cancelada com sucesso"])}
}