export default {
  "home": {
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Bracelet"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant: "])},
    "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation ID not found"])},
    "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough night of stay or reservation available already used"])},
    "t7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date:"])},
    "t8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timetable:"])},
    "t9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persons:"])},
    "t10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
    "t11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments: (Allergies, celebrations or special requests)"])},
    "t12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Availability"])},
    "t13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation Created"])},
    "t14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* There is already a reservation for this date"])},
    "t15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Maximum number of people has been reached"])},
    "t16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Restaurant full"])},
    "t17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Booking window closed"])},
    "t18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "t19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dress code: Formal (Gentlemen: closed shoe, dress shorts or pants, shirt with sleeves)"])},
    "t20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please be aware that the reservation tolerance is of 10 minutes."])},
    "t21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip is not included."])},
    "t22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough night of stay or reservation available already used"])},
    "t23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
    "t24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", thank you for your reservation!"])},
    "t25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you book 'Breakfast Only' as your meal plan. No reservations are required for Umami or Chianti. Complimentary dinner reservations are only for the 'All Inclusive' meal plan with a minimum of 3 to 5 nights onwards."])},
    "t26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This reservation has already checked out"])},
    "t27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make reservation"])},
    "t28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No availability found"])}
  },
  "Por favor agregue una letra al inicio de acuerdo al color de su brazalete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add a letter at the beginning according to the color of your bracelet"])},
  "para amarillo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for yellow"])},
  "para azul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for blue"])},
  "para blanco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for white"])},
  "para gris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for gray"])},
  "para negro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for black"])},
  "para rosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for pink"])},
  "Selecciona el restaurante y horario disponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the restaurant and available time"])},
  "¿Estas seguro que deseas cancelar tu reserva?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel your reservation?"])},
  "Cancelar reserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel reservation"])},
  "Reserva cancelada con éxito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation cancelled successfully"])}
}